$second-breakpoint: 1700px;
$mobile-breakpoint: 640px;
$dark-blue: #004d71;
$light-blue: #4296b4;
$grey: #656565;
$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;
$extra-bold: 800;
$black: 900;

@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&subset=hebrew&display=swap');

$default-font-family: 'Heebo', Helvetica, Arial, sans-serif;