
@mixin font-face($fontName, $fontFile, $weight) {
    font-family: $fontName;
    src: url('../fonts/' + $fontFile + '.eot'); /* IE9 Compat Modes */
    src: url('../fonts/' + $fontFile + '.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/' + $fontFile + '.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/' + $fontFile + '.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: $weight;
    font-style: normal;
    display: swap;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($transforms) {
	   -moz-transform: $transforms;
	     -o-transform: $transforms;
	    -ms-transform: $transforms;
	-webkit-transform: $transforms;
          transform: $transforms;
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression});
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $blur $color;
    -moz-box-shadow:inset $top $left $blur $color;
    box-shadow:inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin filter($filter-type,$filter-amount) { 
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin linear-gradient($args...) {
 background: -o-linear-gradient($args); 
 background: -moz-linear-gradient($args); 
 background: -webkit-linear-gradient($args); 
  background: linear-gradient($args); 
}
