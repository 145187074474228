.align-c {
    text-align: center;
}

.align-l {
    text-align: left;
}

.align-r {
    text-align: right;
}

.hidden {
    display: none !important;
}

.invisible {
    visibility: hidden;
}

.disp-b {
    display: block;
}

.float-l {
    float: left;
}

.float-r {
    float: right;
}

.float-no {
    float: none !important;
}

.pad-no {
    padding: 0 !important;
}

.centered {
    float: none !important;
    margin-left: auto;
    margin-right: auto;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.media-fluid {
    max-width: 100%;
    height: auto;
}

.strong {
    font-weight: bold;
}

.small {
    font-size: 0.8em;
}

@media screen and (max-width: $mobile-breakpoint) {
    .hidden-s {display: none !important};
}

@media screen and (min-width: $mobile-breakpoint+1) {
    .hidden-l {display: none !important};
}