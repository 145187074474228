@charset "utf-8";

// First let's import all boilerplate code
@import "setup/vars";
@import "setup/mixins";
@import "setup/normalize";
@import "setup/helpers";
@import "setup/boilerplate";
@import "setup/burger";
//@import "setup/fonts";
@import url('https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900&subset=hebrew&display=swap');

ul#breadcrumbs{ list-style: none; padding-top: 20px;
    li{display:inline-block; margin-left: 10px;}
}
#header{/*background-size: cover;*/ height: 230px;background-position-y: 36px;}
.mobile-slider{
    display:none!important;
}

.top-section-solutions-mobile{
    display:none!important;
}

.top-section-solutions{
    position: relative;
    display: block;
    width: 1200px;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    font-size: 0;
    top: -138px;
    margin-bottom: 0;
    margin-bottom: 210px;
    background: white;
    @include box-shadow(0px, 2px, 34px, rgba(0, 0, 0, 0.12));
    /* padding-bottom: 100px; */
    
    
    
    .col{
        position: relative;   
        width: 100%;
        min-height: 430px;
        display: block;
        background: white;
        box-sizing: border-box;
        background:none;        
       
        .col-content{  
            position: absolute;
            top: 50%;
            right: 50%;
            margin-right: -154px;
            margin-top: -88px;
            text-align: center;
            h1{
                font-size: 34px;
                color: $light-blue;
                font-weight: 500;                
                margin-top: 8px;
            }
            .hp{font-size: 22px;
                color: $light-blue;
                font-weight: 500;                
                margin-top: 8px;}
            .read-more{
                font-size:18px;
                margin: 0 5px;
                color:$dark-blue;
                font-weight:$medium;
                text-decoration: none;
                position: relative;
                z-index:1;
                @include transition(all 0.3s ease);

                &:hover{
                    color:lighten( $dark-blue, 10% );
                }
        }
        }
    }
}

.mobile-tools{
    display:none;
}



body.admin-bar.home.sticky-categories{
  #home{
    .categories{ top:92px; }
  }
}


@media screen and (max-width: $mobile-breakpoint) {


ul#breadcrumbs{ display:none;}
.top-section-solutions{
        width:90%;
        top: -182px;
        margin-bottom: -140px;    
        .col{
            width: 100%;           
            padding-top: 60px;
        }
        
        h2{
            font-size:40px;
            padding: 30px 90px;
        }
    }   
}

.en{
    .top-section-solutions .col .col-content {   margin-right: -224px;}
}
