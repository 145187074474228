html {
    box-sizing: border-box;
    font-size: 87.5%; // 14px;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    font-family: $default-font-family;
    min-height: 100%;

    * {
        font-family: inherit;
        color: inherit;
    }
}

a {
    text-decoration: none;
    outline: 0;
}

img {
    vertical-align: middle;
}

html, body {
    min-width: 100%;


}

object {
    overflow: hidden;
    background: transparent;
}

body.lead .hidden-lead {
    display: none !important;
}

body.call .hidden-call {
    display: none !important;
}

body.callme .hidden-callme {
    display: none !important;
}

body.undecided .hidden-undecided {
    display: none !important;
}

*[hidden] {
    display:none;
}

fieldset {
    border: none;
    // display: inline-block;
    margin: 0;
    padding: 0;
}

label {
    display: none;
}

input::placeholder {
    opacity: 1;
    font-family: $default-font-family;
    color: inherit
}

input:focus::placeholder {
    opacity: 0;
}

@media screen and (min-width: $mobile-breakpoint) {

    html {
        font-size: 100%; //  16px
    }

}